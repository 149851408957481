import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ArrowRight } from "lucide-react";

interface Language {
  value: string;
  label: string;
  bookId: number;
}

const languages: Language[] = [
  { value: "ar", label: "Arabic", bookId: 133701 },
  { value: "az", label: "Azerbaijani", bookId: 133702 },
  { value: "be", label: "Belarusian", bookId: 133703 },
  { value: "bs", label: "Bosnian", bookId: 133704 },
  { value: "bg", label: "Bulgarian", bookId: 133705 },
  { value: "ca", label: "Catalan", bookId: 133706 },
  { value: "zh-CN", label: "Chinese", bookId: 133707 },
  { value: "zh-TW", label: "Chinese Traditional", bookId: 133708 },
  { value: "hr", label: "Croatian", bookId: 133709 },
  { value: "cs", label: "Czech", bookId: 133710 },
  { value: "da", label: "Danish", bookId: 133711 },
  { value: "nl", label: "Dutch", bookId: 133712 },
  { value: "en", label: "English", bookId: 133713 },
  { value: "et", label: "Estonian", bookId: 133714 },
  { value: "fi", label: "Finnish", bookId: 133715 },
  { value: "fr", label: "French", bookId: 133716 },
  { value: "gl", label: "Galician", bookId: 133717 },
  { value: "de", label: "German", bookId: 133718 },
  { value: "el", label: "Greek", bookId: 133719 },
  { value: "he", label: "Hebrew", bookId: 133720 },
  { value: "hi", label: "Hindi", bookId: 133721 },
  { value: "hu", label: "Hungarian", bookId: 133722 },
  { value: "is", label: "Icelandic", bookId: 133723 },
  { value: "id", label: "Indonesian", bookId: 133724 },
  { value: "it", label: "Italian", bookId: 133725 },
  { value: "ja", label: "Japanese", bookId: 133726 },
  { value: "kk", label: "Kazakh", bookId: 133727 },
  { value: "ko", label: "Korean", bookId: 133728 },
  { value: "lv", label: "Latvian", bookId: 133729 },
  { value: "lt", label: "Lithuanian", bookId: 133730 },
  { value: "ms", label: "Malay", bookId: 133731 },
  { value: "mr", label: "Marathi", bookId: 133732 },
  { value: "ne", label: "Nepali", bookId: 133733 },
  { value: "no", label: "Norwegian", bookId: 133734 },
  { value: "fa", label: "Persian", bookId: 133735 },
  { value: "pl", label: "Polish", bookId: 133736 },
  { value: "pt", label: "Portuguese", bookId: 133737 },
  { value: "ro", label: "Romanian", bookId: 133738 },
  { value: "ru", label: "Russian", bookId: 133739 },
  { value: "sr", label: "Serbian", bookId: 133740 },
  { value: "sk", label: "Slovak", bookId: 133741 },
  { value: "sl", label: "Slovenian", bookId: 133742 },
  { value: "es", label: "Spanish", bookId: 133743 },
  { value: "sw", label: "Swahili", bookId: 133744 },
  { value: "sv", label: "Swedish", bookId: 133745 },
  { value: "tl", label: "Tagalog", bookId: 133746 },
  { value: "ta", label: "Tamil", bookId: 133747 },
  { value: "th", label: "Thai", bookId: 133748 },
  { value: "tr", label: "Turkish", bookId: 133749 },
  { value: "uk", label: "Ukrainian", bookId: 133750 },
  { value: "ur", label: "Urdu", bookId: 133751 },
  { value: "vi", label: "Vietnamese", bookId: 133752 },
];

export function SampleSelector() {
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const handleLanguageSelect = (value: string) => {
    const selected = languages.find((lang) => lang.value === value);
    setSelectedLanguage(selected || null);
  };

  const handleSubmit = () => {
    if (selectedLanguage) {
      router.push(`/book/${selectedLanguage.bookId}`);
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="w-full text-white font-bold py-3 px-4 rounded animated-gradient">
          Get Started with Aesop&apos;s Fables <ArrowRight className="w-4 h-4 ml-2" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select a language for The Fables of Aesop</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Select onValueChange={handleLanguageSelect}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a language" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((language) => (
                <SelectItem key={language.value} value={language.value}>
                  {language.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button onClick={handleSubmit} disabled={!selectedLanguage}>
            Read Sample
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}