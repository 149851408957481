import React, { useEffect, useRef } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

declare global {
  interface Window {
    Stream: any;
  }
}

const StreamPlayer = ({ videoUid, customerCode, tiltDirection = 'right' }: { videoUid: string; customerCode: string; tiltDirection: 'right' | 'left' }) => {
  const videoRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (videoRef.current) {
        const player = window.Stream(videoRef.current);
        player.addEventListener('play', () => {
          console.log('playing!');
        });
        player.play().catch(() => {
          console.log('playback failed, muting to try again');
          player.muted = false;
          player.play();
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const cardX = useMotionValue(0);
  const rotateY = useTransform(cardX, [-300, 300], [-30, 30]);

  const handleMouseMove = (event: React.MouseEvent) => {
    const offsetX = event.clientX - window.innerWidth / 2;
    const maxTilt = 30; 
    const tilt = (offsetX / (window.innerWidth / 2)) * maxTilt; 
    cardX.set(tilt);
  };

  const handleMouseLeave = () => {
    cardX.set(0);
  };

  const aspectRatio = 4 / 3
  const videoHeight = '552.96px';
  const videoWidth = '737.28px';
  const initialTilt = tiltDirection === 'right' ? -30 : 30;

  return (
    <motion.div
      style={{
        perspective: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: videoHeight,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <motion.div
        style={{
          width: videoWidth,
          height: videoHeight,
          rotateY,
        }}
        initial={{ rotateY: initialTilt }}
        whileHover={{ 
          rotateY: 0, 
          scale: 1.05, 
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
          transition: { duration: 0.4 } 
        }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="relative overflow-hidden rounded-lg shadow-lg"
      >
        <iframe
          ref={videoRef}
          src={`https://customer-${customerCode}.cloudflarestream.com/${videoUid}/iframe`}
          style={{ border: 'none', width: '100%', height: '100%' }} 
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
          id="stream-player"
        ></iframe>
      </motion.div>
    </motion.div>
  );
};

export default StreamPlayer;