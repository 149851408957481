import React, { useState, useRef, useEffect } from "react";
import { useRouter } from "next/navigation";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Loader2, AlertCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import debounce from 'lodash/debounce';
import { cn } from "@/lib/utils"; // Make sure you have this utility function

const difficulties = [
  { value: "1", label: "Newbie" },
  { value: "3", label: "Beginner" },
  { value: "5", label: "Intermediate" },
  { value: "7", label: "Upper Intermediate" },
  { value: "9", label: "Advanced" },
  { value: "12", label: "Fluent" },
];

interface Option {
    value: string;
    label: string;
  }

const languages: Option[] = [
    { value: "ar", label: "Arabic" },
    { value: "az", label: "Azerbaijani" },
    { value: "be", label: "Belarusian" },
    { value: "bs", label: "Bosnian" },
    { value: "bg", label: "Bulgarian" },
    { value: "ca", label: "Catalan" },
    { value: "zh-CN", label: "Chinese" },
    { value: "zh-TW", label: "Chinese Traditional" },
    { value: "hr", label: "Croatian" },
    { value: "cs", label: "Czech" },
    { value: "da", label: "Danish" },
    { value: "nl", label: "Dutch" },
    { value: "en", label: "English" },
    { value: "et", label: "Estonian" },
    { value: "fi", label: "Finnish" },
    { value: "fr", label: "French" },
    { value: "gl", label: "Galician" },
    { value: "de", label: "German" },
    { value: "el", label: "Greek" },
    { value: "he", label: "Hebrew" },
    { value: "hi", label: "Hindi" },
    { value: "hu", label: "Hungarian" },
    { value: "is", label: "Icelandic" },
    { value: "id", label: "Indonesian" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "kk", label: "Kazakh" },
    { value: "ko", label: "Korean" },
    { value: "lv", label: "Latvian" },
    { value: "lt", label: "Lithuanian" },
    { value: "ms", label: "Malay" },
    { value: "mr", label: "Marathi" },
    { value: "ne", label: "Nepali" },
    { value: "no", label: "Norwegian" },
    { value: "fa", label: "Persian" },
    { value: "pl", label: "Polish" },
    { value: "pt", label: "Portuguese" },
    { value: "ro", label: "Romanian" },
    { value: "ru", label: "Russian" },
    { value: "sr", label: "Serbian" },
    { value: "sk", label: "Slovak" },
    { value: "sl", label: "Slovenian" },
    { value: "es", label: "Spanish" },
    { value: "sw", label: "Swahili" },
    { value: "sv", label: "Swedish" },
    { value: "tl", label: "Tagalog" },
    { value: "ta", label: "Tamil" },
    { value: "th", label: "Thai" },
    { value: "tr", label: "Turkish" },
    { value: "uk", label: "Ukrainian" },
    { value: "ur", label: "Urdu" },
    { value: "vi", label: "Vietnamese" },
  ];

  const PdfUrlProcessor = () => {
    const router = useRouter();
    const [pdfUrl, setPdfUrl] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [processError, setProcessError] = useState<string | null>(null);
    const [fileKey, setFileKey] = useState<string | null>(null);
    const [jsonFileKey, setJsonFileKey] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOriginalLanguage, setSelectedOriginalLanguage] = useState("");
    const [selectedTargetLanguage, setSelectedTargetLanguage] = useState("");
    const [selectedDifficulty, setSelectedDifficulty] = useState("");
    const urlInputRef = useRef<HTMLInputElement>(null);
  
    useEffect(() => {
      if (urlInputRef.current) {
        urlInputRef.current.focus();
      }
    }, []);
  
    const processUrlInput = async (url: string) => {
      if (!url.toLowerCase().endsWith('.pdf')) {
        setProcessError("The URL must end in .pdf. Try searching for 'Meditations filetype:pdf'.");
        setFileKey(null);
        return;
      }
  
      setIsProcessing(true);
      setProcessError(null);
  
      try {
        const response = await axios.get('https://5vcvzuuwua.execute-api.us-east-1.amazonaws.com/prod/', {
          params: { url: url }
        });
  
        if (response.data && response.data.body) {
          const parsedBody = JSON.parse(response.data.body);
          if (parsedBody.txtFileKey) {
            const txtFileKey = parsedBody.txtFileKey;
            const jsonFileKey = txtFileKey.replace('.txt', '.json');
            setFileKey(txtFileKey);
            setJsonFileKey(jsonFileKey);
            setIsModalOpen(true);
            toast.success("PDF processed successfully");
          } else {
            throw new Error("Invalid response from server");
          }
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error('Error processing PDF:', error);
        setProcessError("Error processing PDF. Please try another URL.");
      } finally {
        setIsProcessing(false);
      }
    };
  
    const debouncedProcessUrlInput = debounce((url) => processUrlInput(url), 500);
  
    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const url = e.target.value;
      setPdfUrl(url);
  
      if (url && !url.toLowerCase().endsWith('.pdf')) {
        setProcessError("The URL must end in .pdf");
        setFileKey(null);
      } else {
        setProcessError(null);
        if (url) {
          debouncedProcessUrlInput(url);
        } else {
          setFileKey(null);
        }
      }
    };
  
    const { mutate, isLoading } = useMutation({
      mutationFn: async (payload: {
        file_key: string;
        original_language: string;
        target_language: string;
        difficulty: string;
        json_file_key: string;
      }) => {
        const response = await axios.post("/api/create-book", payload);
        return response.data;
      },
    });
  
    const handleSubmit = () => {
      if (!fileKey || !jsonFileKey || !selectedOriginalLanguage || !selectedTargetLanguage || !selectedDifficulty) {
        toast.error("Please fill in all required fields");
        return;
      }
  
      const payload = {
        file_key: fileKey,
        original_language: selectedOriginalLanguage,
        target_language: selectedTargetLanguage,
        difficulty: selectedDifficulty,
        json_file_key: jsonFileKey,
      };
  
      mutate(payload, {
        onSuccess: (data) => {
          const newBookId = data.book_id;
          toast.success("Book created successfully");
          router.push(`/book/${newBookId}`);
        },
        onError: (err) => {
          console.error(err);
          toast.error("Error creating book");
        },
      });
    };
  
    return (
        <div className="space-y-4 relative">
          <div className="space-y-2 relative">
            <div className="relative md:left-1/2 md:transform md:-translate-x-1/2 w-full md:w-[150%]">
              <Input 
                type="url" 
                placeholder="Enter PDF URL" 
                value={pdfUrl}
                onChange={handleUrlChange}
                disabled={isProcessing}
                ref={urlInputRef}
                className={cn(
                  "text-black placeholder-gray-500 bg-white border-gray-300",
                  "transition-all duration-300",
                  "w-full md:h-[150%] md:text-lg" // Increased size for non-mobile screens
                )}
              />
            </div>
            {isProcessing && (
              <div className="flex items-center justify-center space-x-2 text-sm text-blue-500 mt-2">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span>Processing URL. This may take up to a minute...</span>
              </div>
            )}
            {processError && (
              <div className="flex items-center justify-center space-x-2 text-sm text-red-500 mt-2">
                <AlertCircle className="w-4 h-4" />
                <span>{processError}</span>
              </div>
            )}
          </div>
  
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Book Details</DialogTitle>
              <DialogDescription>Enter the book details to create your personalized reading experience.</DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="original-language" className="text-right">Original Language</Label>
                <Select value={selectedOriginalLanguage} onValueChange={setSelectedOriginalLanguage}>
                  <SelectTrigger id="original-language" className="col-span-3">
                    <SelectValue placeholder="Select language" />
                  </SelectTrigger>
                  <SelectContent>
                    {languages.map((lang) => (
                      <SelectItem key={lang.value} value={lang.value}>{lang.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="target-language" className="text-right">Target Language</Label>
                <Select value={selectedTargetLanguage} onValueChange={setSelectedTargetLanguage}>
                  <SelectTrigger id="target-language" className="col-span-3">
                    <SelectValue placeholder="Select language" />
                  </SelectTrigger>
                  <SelectContent>
                    {languages.map((lang) => (
                      <SelectItem key={lang.value} value={lang.value}>{lang.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="difficulty" className="text-right">Difficulty</Label>
                <Select value={selectedDifficulty} onValueChange={setSelectedDifficulty}>
                  <SelectTrigger id="difficulty" className="col-span-3">
                    <SelectValue placeholder="Select difficulty" />
                  </SelectTrigger>
                  <SelectContent>
                    {difficulties.map((diff) => (
                      <SelectItem key={diff.value} value={diff.value}>{diff.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter>
              <Button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? "Creating Book..." : "Create Book"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  
  export default PdfUrlProcessor;
  