import React from 'react';
import StreamPlayer from './StreamPlayer';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUid: string;
  customerCode: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoUid, customerCode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg max-w-3xl w-full">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        <div className="mt-2">
          <StreamPlayer videoUid={videoUid} customerCode={customerCode} tiltDirection="right" />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;